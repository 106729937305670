(function (factory) {
    typeof define === 'function' && define.amd ? define(factory) :
    factory();
})((function () {
    /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
    const __meta__ = {
        id: "polifill",
        name: "Polifill",
        category: "web",
        description: "A polifill for kendo. Typically injected in messages and cultures.",
        depends: [ ],
    };

    if (!window.kendo) {
        console.error("kendo is not loaded.", "Ensure that kendo scripts are loaded before this script.");
    }

    (function( window, undefined$1 ) {
        kendo.cultures["en-AU"] = {
            name: "en-AU",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": ",",
                ".": ".",
                groupSize: [3],
                percent: {
                    pattern: ["-n%","n%"],
                    decimals: 2,
                    ",": ",",
                    ".": ".",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "Australian Dollar",
                    abbr: "AUD",
                    pattern: ["-$n","$n"],
                    decimals: 2,
                    ",": ",",
                    ".": ".",
                    groupSize: [3],
                    symbol: "$"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],
                        namesAbbr: ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"],
                        namesShort: ["Su","Mo","Tu","We","Th","Fr","Sa"]
                    },
                    months: {
                        names: ["January","February","March","April","May","June","July","August","September","October","November","December"],
                        namesAbbr: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
                    },
                    AM: ["AM","am","AM"],
                    PM: ["PM","pm","PM"],
                    patterns: {
                        d: "d/MM/yyyy",
                        D: "dddd, d MMMM yyyy",
                        F: "dddd, d MMMM yyyy h:mm:ss tt",
                        g: "d/MM/yyyy h:mm tt",
                        G: "d/MM/yyyy h:mm:ss tt",
                        m: "d MMMM",
                        M: "d MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "h:mm tt",
                        T: "h:mm:ss tt",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM yyyy",
                        Y: "MMMM yyyy"
                    },
                    "/": "/",
                    ":": ":",
                    firstDay: 1
                }
            }
        };
    })();

}));
